import {
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { AppContext } from '../../../../contexts/AppContext'
import {
  IAdvertiserInDb,
  IAssignPCampaignToCampaign,
  ICampaignInDb,
  IPCampaign,
} from '../../../../api/imi/interfaces'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import FormButtonGroup from '../../../button/FormButtonGroup'
import ToolTip from '../../../ToolTip'
import AssignCampaignForm from '../../../form/campaigns/campaign_settings/AssignCampaignForm'
import LoadingSpinner from '../../../LoadingSpinner'
import Radio from '../../../form/Radio'
import AssignAdvertiserForm from '../../../form/campaigns/campaign_settings/CreateAssignCampaignForm'
import { newCampaignInDb } from '../../../../api/imi/factories'

interface Props {
  isLoading: boolean
  createdCampaigns: ICampaignInDb[]
  pCampaign: IPCampaign
  allAdvertisers?: IAdvertiserInDb[]
}

const CampaignAssignment:React.FC<Props> = ({
  isLoading,
  createdCampaigns,
  pCampaign,
  allAdvertisers,
}) => {
  const { setError } = useContext(AppContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState<boolean>(true)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  // TODO: was <IAssignPCampaignToCampaign>

  const methods = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      assign_p_campaign: campaignInDb?.id > 0 ? campaignInDb?.type?.id !== 2 : pCampaign?.type_id !== 2,
      campaign_id: campaignInDb?.id > 0 ? campaignInDb?.id : 0,
      pcampaign_id: pCampaign?.id,
    }), [campaignInDb, pCampaign]),
  })
  const assignPCampaign = methods.watch('assign_p_campaign')
  // useEffect(() => {
  //   if (assignPCampaign === 'false' || assignPCampaign === false) {
  //     methods.setValue('assign_p_campaign', 1)
  //   }
  // }, [assignPCampaign, methods])

  useEffect(() => {
    methods.reset(
      {
        assign_p_campaign: campaignInDb?.id > 0 ? campaignInDb?.type?.id !== 2 : pCampaign?.type_id !== 2,
        campaign_id: campaignInDb?.id > 0 ? campaignInDb?.id : 0,
        pcampaign_id: pCampaign?.id,
      },
    )
  }, [campaignInDb, pCampaign, methods])

  const cancel = () => {
    methods.reset(
      {
        assign_p_campaign: campaignInDb?.id > 0 ? campaignInDb?.type?.id !== 2 : pCampaign?.type_id !== 2,
        campaign_id: campaignInDb?.id > 0 ? campaignInDb?.id : 0,
        pcampaign_id: pCampaign?.id,
      },
    )
    setBtnText('Save')
    setShowButton(false)
    methods.clearErrors()
    setIsButtonLoading(false)
  }

  // NOTE: take the campaignId and add the advertiser ID
  // <IAssignPCampaignToCampaign>
  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    // advertiser_id, assign_p_campaign, campaign_id, pcampaign_id

    // NOTE: this is for SEM campaigns ONLY
    // in this case we're not only creating a Campaign, but also
    // assigning the pcampaign. This is hidden in the UI, and the
    // UI is also completely different for SEM campaign types.

    if (data.assign_p_campaign === 'false' || data.assign_p_campaign === false) {
      const formData = {
        is_active: true,
        name: data.name,
        secondary_name: pCampaign?.secondary_name || '',
        uplift_base: 0,
        roi_is_active: false,
        call_processing_is_active: false,
        advertiser_id: data?.advertiser_id,
        // category_id: 0,
        // specific_id: 0,
        pcampaign_id: data?.pcampaign_id,
        type_id: pCampaign?.type_id,
        id: data.id,
      }

      const submitResponse = await IMIApi.CreateCampaign(formData)
      setTimeout(() => {
        if (submitResponse.status !== 200) {
          if (submitResponse.status === 0) {
            setIsButtonLoading(false)
            setError({ errorMsg: 'Internal server error.' })
            return setBtnText('Save')
          }
          const parse = JSON.parse(submitResponse)
          setIsButtonLoading(false)
          setError({ errorMsg: parse.detail })
          return setBtnText('Save')
        }
        setIsButtonLoading(false)
        setBtnText('Saved!')
        setCampaignInDb(submitResponse.data)
      }, 1000)
      setTimeout(() => {
        setShowButton(false)
        return setBtnText('Save')
      }, 2000)
      return
    }
  
    const submitResponse = await IMIApi.AssignPCampaignToCampaign(data)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      setBtnText('Saved!')
      setShowButton(false)
      setBtnText('Save')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  const edit = () => {
    setShowButton(true)
  }

  return (
    <>
      <Content
        title="campaign assignment"
        icon={(
          <ToolTip
            size="sm"
            position="relative"
            tipId="campaign-assignment-info"
          >
            All video and display campaigns have a parent campaign (whether or not there is only one campaign, or multiple campaigns assigned to it).
            <br />
            <br />
            The “available campaigns” list displays created (parent) campaigns only. If no campaigns are appearing, or you can’t find what you’re looking for, you many need to create a new campaign first and then refresh this list.
          </ToolTip>
        )}
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
            >
              {pCampaign?.type_id === 2 && (
                <Radio
                  name="assign_p_campaign"
                  disabled={!showButton}
                  label="Should this campaign be assigned to a parent campaign?"
                  options={[
                    {
                      id: 0,
                      label: 'Yes',
                      value: true,
                    },
                    {
                      id: 1,
                      label: 'No',
                      value: false,
                    },
                  ]}
                />
              )}
              {(assignPCampaign === false || assignPCampaign === 'false') && pCampaign?.type_id === 2 ? (
                <>
                  <p>By selecting this and saving, you will create a new SEM campaign with this one child campaign assigned. If you need to add a child campaign to an existing SEM campaign, please select &quot;Yes&quot; to see the list of available SEM campaigns. </p>
                  <AssignAdvertiserForm
                    showButton={showButton}
                    errors={methods.formState.errors}
                    allAdvertisers={allAdvertisers || []}
                  />
                </>
              ) : (
                <AssignCampaignForm
                  createdCampaigns={createdCampaigns?.filter((campaign) => campaign?.is_active && campaign?.type?.id === (pCampaign?.type_id))}
                  errors={methods.formState.errors}
                  showButton={showButton}
                />
              )}

              <FormButtonGroup
                isButtonLoading={isButtonLoading}
                // disabled={!methods.formState.isDirty || !methods.formState.isValid || campaignInDb.id <= 0}
                disabled={!methods.formState.isDirty || !methods.formState.isValid}
                disabledCancel={!methods.formState.isDirty}
                onClickCancel={cancel}
                onClickEdit={edit}
                btnText={btnText}
                showButton={showButton}
              />
            </form>
          </FormProvider>
        )}
      </Content>
    </>
  )
}

export default CampaignAssignment
