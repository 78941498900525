import { setFontAndSize } from 'pdf-lib'
import { PDF, FontStyle } from '../lib'
import * as sizes from '../sizes'

const ROI_NOTES_HEIGHT = 200

export const drawRoiNotes = (pdf: PDF) => {
  const title = pdf.text.drawText('roi notes', pdf.colors.black, { style: FontStyle.Bold })
  const titleHeight = title.height + sizes.CONTAINER_MARGIN * 2
  const bodyHeight = ROI_NOTES_HEIGHT + sizes.CONTAINER_MARGIN * 2
  const totalHeight = titleHeight + bodyHeight

  if (totalHeight > pdf.pages.remainingHeight) {
    pdf.pages.nextPage()
  }

  pdf.drawing.drawContainerHeader(pdf.pages.pageWidth, titleHeight)
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  title.render()
  pdf.pages.moveDown(titleHeight - sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()
  pdf.drawing.drawContainerFooter(pdf.pages.pageWidth, bodyHeight, pdf.colors.white)
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)

  const form = pdf.document.getForm()
  const textField = form.createTextField('roi.notes')
  textField.enableMultiline()
  const da = textField.acroField.getDefaultAppearance() ?? ''
  const newDa = `${da}\n${setFontAndSize(pdf.fonts.heebo.name, 12).toString()}`
  textField.acroField.setDefaultAppearance(newDa)
  textField.updateAppearances(pdf.fonts.heebo)
  textField.addToPage(pdf.pages.currentPage, { 
    x: pdf.pages.currentX,
    y: pdf.pages.currentY - ROI_NOTES_HEIGHT,
    textColor: pdf.colors.darkestGrey,
    font: pdf.fonts.heebo,
    borderWidth: 0,
    backgroundColor: undefined,
    width: pdf.pages.pageWidth - sizes.CONTAINER_MARGIN * 2,
    height: ROI_NOTES_HEIGHT,
  })
  pdf.pages.resetX()
  pdf.pages.moveDown(ROI_NOTES_HEIGHT)
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveDown(sizes.SECTION_MARGIN)
}
